import {
  afterNextRender,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import {HomepageService} from "../../services/homepage/homepage.service";
import {FooterService} from "../../services/footer/footer.service";
import {AmplitudeService} from "../../services/tracking/amplitude.service";
import {SpaceDetails} from "../../interfaces/spaceDetails";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {Product} from "../../interfaces/product";
import {ActivatedRoute, Router} from "@angular/router";
import {getWebLink, productFinderFunction} from "../../utils/common-function";
import {SkimlinksService} from "../../services/tracking/skimlinks.service";
import {PLACEHOLDER_URL} from "../../shared/constants";
import {ScreenService} from "../../services/screen/screen.service";


@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrl: './homepage.component.scss',
    standalone: false
})
export class HomepageComponent implements OnInit, AfterViewInit {

  productIndex: number = -1
  showProductModal: boolean = false;
  mobileSliderProducts: Product[] | undefined;
  lineCount: number = 0;

  isServer: boolean;

  externalLinkPopup: boolean = false;

  constructor(
    public homepageService: HomepageService,
    public footerService: FooterService,
    public amplitudeService: AmplitudeService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    public skimlinksService: SkimlinksService,
    public ngZone: NgZone,
    public screenService: ScreenService,
  ) {
    this.isServer = isPlatformServer(this.platformId)
    afterNextRender({ write: () => {
        this.ngZone.run(() => {
            this.lineCount = this.getPropertyNameLineCount();
            this.changeDetectorRef.detectChanges();
        });
        this.homepageService.refreshAOS();
    } }, )
  }

  get isMobile() {
    console.log(this.screenService.isMobile())
    return isPlatformBrowser(this.platformId) && window.innerWidth < 767
  }


  crouselConfig = {
    breakpoints: {
      "(max-width: 80000px)": {
        slides: { perView: 4, spacing: 30 },
      },
      "(max-width: 1310px)": {
        slides: { perView: 4, spacing: 30 },
      },
      "(max-width: 1000px)": {
        slides: { perView: 4, spacing: 10 },
      },
      "(max-width: 991px)": {
        slides: { perView: 3.4, spacing: 10 },
      },
      "(max-width: 767px)": {
        slides: { perView: 2.5, spacing: 10},
      },
    },
    drag: false,
    loop: true,
    slides: {
      perView: 4,
      spacing: 12,
    }
  }

  ngOnInit(): void {
    this.footerService.isMobileNavOpen.next(true);
    this.amplitudeService.screenImpressionEvent('homepage')
    this.homepageService.updateMetaAndTitleTags(`Shop Your Stay | ${this.homepageService.SHOWROOM_DETAILS()?.showroomName!}`, this.homepageService.SHOWROOM_DETAILS()?.image!)
    if (isPlatformBrowser(this.platformId)) {
      this.skimlinksService.loadAffliateLinks(`${this.footerService.PropertyId()}-${this.footerService.PROPERTY_DETAILS().id}`);
    }
    this.homepageService.ACTIVE_SCREEN = 'homepage';

  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe({
      next: (params) => {
        if (params['productId']) {
          const productIndex = this.homepageService.SHOWROOM_DETAILS()?.allProductList.products.findIndex(product => productFinderFunction(product, params['productId']))!;
          if (productIndex !== -1) {
            const space = this.homepageService.SELECTED_SPACE() ? this.homepageService.SELECTED_SPACE() : this.getSpace(params['productId']); //this.showroomDetails?.allProductList!
            const index = space?.subspaceId === 'all_products' ? productIndex : space?.products.findIndex(each => productFinderFunction(each, params['productId']));
            // Open product modal
            if (space) {
              this.openProductModal(space.products, index!, space.subspaceId !== 'all_products');
            }
            const product = this.homepageService.SHOWROOM_DETAILS()?.allProductList?.products[productIndex!]!
            this.homepageService.updateMetaAndTitleTags(`${product.productName}`, product.image)
            this.changeDetectorRef.detectChanges();
          } else {
            this.router.navigate([`/${this.footerService.PropertyId()}`]).then();
          }
        } else {
          this.showProductModal = false;
        }
      }
    })

    if (isPlatformBrowser(this.platformId)) {
      if (this.homepageService.CLICK_PRODUCT_ID()) {
        setTimeout(() => {
          const productElement = document.getElementById(this.homepageService.CLICK_PRODUCT_ID())
          if (productElement) {
            productElement.click();
          }
          this.homepageService.CLICK_PRODUCT_ID.set('');
        }, 500)
      }
    }

  }

  /**
   * Return space where product exist
   * @param productId
   */
  getSpace(productId: any) {
    return this.homepageService.PROPERTY_DETAILS()?.spaceList.find(function (e: SpaceDetails) {
      return e?.products.find(p => p.showroomProductName === productId);
    })
  }

  /**
   * Return property name line count
   */
  getPropertyNameLineCount(): number {
    if (isPlatformBrowser(this.platformId)) {
      const divElement = document.getElementById('propertyName') as HTMLElement;
      const computedStyle = window.getComputedStyle(divElement);
      const lineHeight = parseFloat(computedStyle.lineHeight);
      const containerHeight = divElement.clientHeight;
      return containerHeight / lineHeight;
    }
    return 1;
  }

  /**
   * To track slick arrow click
   * @param spaceInformation Space information
   */
  afterChange(spaceInformation: SpaceDetails | undefined) {
    const spaceName = spaceInformation?.subspaceId === 'our_favorite' ? 'our_favorites' : spaceInformation?.subspaceId === 'local_items' ? 'local_items' : spaceInformation?.title!;
    this.amplitudeService.productCarouselNextClick(spaceName);
  }

  openProductModal(products: Product[], productIndex: number, slice: boolean = true) {
    this.mobileSliderProducts = slice ? products.length > 12 ? [...products.slice(0, 11)] : [...products] : [...products]
    this.productIndex = productIndex
    this.showProductModal = true;
  }


  /**
   * Open mobile product slider
   * @param event Click event
   * @param spaceDetails Space Details
   * @param product
   */
  openProduct(event: any, spaceDetails: SpaceDetails, product :Product) {
    this.homepageService.SELECTED_SPACE.set(spaceDetails);
    const products = spaceDetails.products
    if (!product.affiliateUrl && !product.productUrl) {
      event.preventDefault()
    }
    const isMobile = isPlatformBrowser(this.platformId) && window.innerWidth < 767
    this.mobileSliderProducts = [];
    if (isMobile) {
      event.preventDefault();
      this.homepageService.SHOULD_SCROLL.set(false);
      const queryParams = {
        productId: product.showroomProductName
      }
      this.router.navigate([`/${this.footerService.PropertyId()}`], { queryParams }).then();
    }
    if (products && product) {
      if (this.homepageService._IS_IFRAME() && !isMobile) {
        // Open modal and prevent default
        event.preventDefault();
        this.externalLinkPopup = true;
      } else {
        this.amplitudeService.productCardClickEvent(product, 'homepage')
      }
    }
  }

  protected readonly getWebLink = getWebLink;
  protected readonly PLACEHOLDER_URL = PLACEHOLDER_URL;
}
