import {afterNextRender, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {InvisibleReCaptchaComponent} from 'ngx-captcha';
import {EMAIL_REGEX} from "../../shared/constants";
import {isPlatformBrowser} from "@angular/common";
import {FooterService} from "../../services/footer/footer.service";
import {AmplitudeService} from "../../services/tracking/amplitude.service";
import {NavigationEnd, Router} from "@angular/router";
import {HomepageService} from "../../services/homepage/homepage.service";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
    standalone: false
})
export class FooterComponent implements OnInit{

  @ViewChild('captchaElem') captchaElem: InvisibleReCaptchaComponent | undefined;
  @ViewChild('stayTouchInputElem') stayTouchInputElem: any;
  @ViewChild('myInput') myInput: any;
  constructor(
    private footerService: FooterService,
    public changeDetectorRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    public amplitudeService: AmplitudeService,
    public router: Router,
    public homepageService: HomepageService,
  ) {
    afterNextRender(() => {
      if(isPlatformBrowser(this.platformId)) {
        this.showRecaptcha = true;
        this.changeDetectorRef.detectChanges()
      }
    })
    this.router.events.subscribe({
      next: (event) => {
        if(event instanceof NavigationEnd) {
          this.subscriptionForm.reset({
            email: '',
          })
          this.isFormSubmitted = false;
        }
      }
    })
  }


  subscriptionForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)])
  });

  isFormSubmitted: boolean = false;
  isSuccess: boolean = false;
  email: string = "";
  stopReClick: boolean = false;
  placeholderText: string = "Email Address";
  showRecaptcha: boolean = false;
  today = new Date()

  ngOnInit(): void {
  }

  /**
   * Getter for login form controls
   */
  get newsletterFormControl() {
    return this.subscriptionForm.controls
  }

  /**
   * Focus in input box
   */
  focusInEvent() {
    this.placeholderText= '';
  }

  /**
   * Focus out from input box
   */
  focusOutEvent() {
    this.placeholderText= 'Email Address';
  }

  /**
   * On Input event on re enter on correct mail
   */
  reEnterEmail() {
    this.isFormSubmitted = false;
  }

  /**
   * Execute invisible google captcha
   */
  executeRecaptcha(){
    this.isFormSubmitted = true;
    this.footerClickEventTracker('newsletter_submit')
    if(this.subscriptionForm.valid) {
      this.captchaElem?.execute();
    }
  }

  /**
   * Submit form on success captcha
   * @param event
   */
  handleSuccess = (event: string) => {
    // console.log(event);
    this.onSubmitForm();
  }


  /**
   * On submit newsletter mail
   */
  onSubmitForm() {
    this.isFormSubmitted = true;
    let formData: any = this.subscriptionForm.value;
    if(formData.email.trim() !== ""){
      let requestBody = {
        name: 'Guest',
        email: formData.email,
        contactNumber:'-',
        message: 'Keep In Touch',
        type: "default",
        source: this.footerService.PropertyId(),
        // isNewWebsite: true,
      }

      //Call APi to store contact us details
      this.footerService.sendKeepInTouchInformation(requestBody).subscribe({
        next: (resp: any) => {
          this.isSuccess = true;
          setTimeout(() => {
            // To reset success message
            this.switchEmailSubmitted();
          }, 3000)
        },
        error: (error) => {
          console.log(error);
        }
      })
    }

  }

  switchEmailSubmitted() {
    this.subscriptionForm.reset();
    this.isFormSubmitted = false;
    this.captchaElem?.resetCaptcha();
    this.isSuccess = false;
    this.placeholderText= 'Email Address';
  }

  /**
   * Finds current screen name and trackers footer click event
   * @param buttonName
   */
  footerClickEventTracker(buttonName: string) {
    const url = this.router.url;
    const allSpaces = this.homepageService.PROPERTY_DETAILS()?.spaceList.map(each => each?.subspaceName!)
    let screenName = ''
    if(url.includes('/all-products')) {
      screenName = 'all_products';
    } else if (url.includes('/our-favorites')) {
      screenName = 'our_favorites';
    } else if (url.includes('/local-items')) {
      screenName = 'local_items';
    } else if (allSpaces && allSpaces.some(spaceId => url.includes(spaceId))) {
      screenName = 'space_page';
    } else {
      screenName = 'homepage';
    }
    this.amplitudeService.footerClickEvent(buttonName, screenName);
  }

}
