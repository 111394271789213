import {ResolveFn} from '@angular/router';
import {inject, PLATFORM_ID} from "@angular/core";
import {HomepageService} from "../services/homepage/homepage.service";
import {isPlatformBrowser} from "@angular/common";

export const propertyDataResolver: ResolveFn<boolean> = async (route, state): Promise<any> => {
  const homepageService = inject(HomepageService);
  const platformId = inject(PLATFORM_ID);
  const navigatedFromGallery = route.queryParams['source'];

  if(isPlatformBrowser(platformId) && sessionStorage.getItem('showBookAStay') === 'true') {
    homepageService.SHOW_BOOK_A_STAY.set(true);
  } else {
    if(navigatedFromGallery && navigatedFromGallery === 'gallery') {
      homepageService.SHOW_BOOK_A_STAY.set(true);
      if(isPlatformBrowser(platformId)) {
        sessionStorage.setItem('showBookAStay', 'true');
      }
    } else {
      // For safety, set false
      homepageService.SHOW_BOOK_A_STAY.set(false);
    }
  }

  const promises: Promise<any>[] = []
  if(!homepageService.PROPERTY_DETAILS()) {
    promises.push(homepageService.fetchPageInformation(route.params['propertyId']));
  }
  if(!homepageService.SHOWROOM_LIST().length) {
    promises.push(homepageService.fetchShowroomList(route.params['propertyId']));
  }
  await Promise.allSettled(promises);
  return homepageService.PROPERTY_DETAILS()!;
};


