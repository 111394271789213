import {Component} from '@angular/core';
import {HomepageService} from "../../../services/homepage/homepage.service";
import {getWebLink} from "../../../utils/common-function";
import {FooterService} from "../../../services/footer/footer.service";
import {ApplyFnModule} from "../../pipes/applyFn/apply-fn.module";
import {AsyncPipe} from "@angular/common";
import {AmplitudeService} from "../../../services/tracking/amplitude.service";

@Component({
    selector: 'app-book-a-stay-cta',
    imports: [
        ApplyFnModule,
        AsyncPipe
    ],
    standalone: true,
    templateUrl: './book-a-stay-cta.component.html',
    styleUrl: './book-a-stay-cta.component.scss'
})
export class BookAStayCtaComponent {

  constructor(
    public homepageService: HomepageService,
    public footerService: FooterService,
    public amplitudeService: AmplitudeService,
  ) {

  }

  protected readonly getWebLink = getWebLink;

}
