import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HomepageService} from "../../services/homepage/homepage.service";
import {FooterService} from "../../services/footer/footer.service";
import {AmplitudeService} from "../../services/tracking/amplitude.service";
import {isPlatformBrowser} from "@angular/common";
import {SkimlinksService} from "../../services/tracking/skimlinks.service";

@Component({
    selector: 'app-all-products',
    templateUrl: './all-products.component.html',
    styleUrl: './all-products.component.scss',
    standalone: false
})
export class AllProductsComponent implements OnInit {

  constructor(
    public homepageService: HomepageService,
    public footerService: FooterService,
    public amplitudeService: AmplitudeService,
    public skimlinksService: SkimlinksService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {

  }

  ngOnInit() {
    this.amplitudeService.screenImpressionEvent('all_products')
    this.homepageService.updateMetaAndTitleTags(`Shop Your Stay - ${this.homepageService.PROPERTY_DETAILS()!.showroomDetails?.allProductList.title!} | ${this.homepageService.PROPERTY_DETAILS()?.showroomDetails.showroomName!}`, this.homepageService.PROPERTY_DETAILS()?.showroomDetails?.image!)
    if(isPlatformBrowser(this.platformId)) {
      this.skimlinksService.loadAffliateLinks(`${this.footerService.PropertyId()}-${this.footerService.PROPERTY_DETAILS().id}`);
    }
  }

}
