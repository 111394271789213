import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {HomepageService} from "../services/homepage/homepage.service";

/**
 * Guard to prevent access to local-items, our-favorites pages if no products are available in them
 * @param route Activated Route Snapshot
 * @param state Current Router State
 */
export const localItemsGuard: CanActivateFn = async (route, state) => {
  const homepageService: HomepageService = inject(HomepageService);
  const router: Router = inject(Router);
  const propertyId = state.url.split('/')[1]
  const homeUrl = router.parseUrl(`/${propertyId}`)

  if(!homepageService.PROPERTY_DETAILS()) {
    await homepageService.fetchPageInformation(propertyId);
  }

  if(state.url.includes('/local-items')) {
    return !!homepageService.PROPERTY_DETAILS()?.showroomDetails.localItemsProductList.productCount ? true : homeUrl
  }

  if(state.url.includes('/our-favorites')) {
    return !!homepageService.PROPERTY_DETAILS()?.showroomDetails.ourFavoriteProductList.productCount ? true : homeUrl
  }

  return true;
};
