import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {isPlatformServer} from "@angular/common";


@Component({
    selector: 'app-title-display',
    templateUrl: './title-display.component.html',
    styleUrl: './title-display.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TitleDisplayComponent implements OnChanges, AfterViewInit {
  @Input({ transform: (value: string) => value.trim() }) title: string = '';
  @Input() productCount: number = 0;
  displayTitle: string = '';
  @ViewChild('titleElement') titleElement: ElementRef | undefined;
  @ViewChild('countElement', { static: false }) countElement: ElementRef | undefined;
  constructor(
    public changeDetectionRef: ChangeDetectorRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  ngAfterViewInit() {
    this.setCharacters();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['title']) {
      if (changes['title'].currentValue !== changes['title'].previousValue) {
        this.displayTitle = '';
        this.setCharacters();
      }
    }
  }


  setCharacters() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    if (this.titleElement) {
      window.innerWidth < 767 ? this.setTextOnMobile() : this.setTextOnDesktop();
    }

  }

  updateDom() {
    const countString = `
    <div #countElement class="count_wrapper count_mobile">
    <span class="inner_count">
      <i class="icon">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.79614 13.125C5.34872 13.125 4.92785 12.9506 4.61125 12.6343L0.916038 8.93904C0.599437 8.62244 0.425293 8.20157 0.425293 7.75416C0.425293 7.30674 0.599708 6.88587 0.916038 6.56927L6.86944 0.615872C7.25673 0.228584 7.80814 0.054711 8.3498 0.151127L11.4839 0.712288C12.1707 0.835245 12.7148 1.37934 12.8377 2.06617L13.3989 5.20022C13.4953 5.73972 13.3217 6.29303 12.9342 6.68058L6.98102 12.6337C6.66442 12.9503 6.24355 13.1245 5.79614 13.1245V13.125ZM8.05459 1.20845C7.89887 1.20845 7.74747 1.26993 7.63535 1.38205L1.68222 7.33518C1.4512 7.5662 1.4512 7.94211 1.68222 8.17313L5.37743 11.8683C5.60872 12.0996 5.98409 12.0996 6.21538 11.8683L12.1685 5.91522C12.3056 5.77818 12.3668 5.58237 12.3326 5.3917L11.7715 2.25765C11.7281 2.01498 11.5359 1.82269 11.2929 1.77909L8.15886 1.21793C8.1242 1.2117 8.08926 1.20872 8.05459 1.20872V1.20845Z"
            fill="#666666" />
          <path
            d="M9.90429 4.63817C10.353 4.63817 10.7168 4.2744 10.7168 3.82568C10.7168 3.37695 10.353 3.01318 9.90429 3.01318C9.45556 3.01318 9.0918 3.37695 9.0918 3.82568C9.0918 4.2744 9.45556 4.63817 9.90429 4.63817Z"
            fill="#666666" />
        </svg>

      </i>
      ${this.productCount}</span>
  </div>
    `
    this.renderer.setStyle(this.titleElement?.nativeElement, 'visibility', 'visible')
    this.renderer.setProperty(this.titleElement?.nativeElement, 'innerHTML', this.displayTitle + countString)
  }


  //This method is only responsible for handling mobile screens
  setTextOnMobile() {
    setTimeout(() => {
      const { lineCountWhenCountInvisible, lineCountWhenCountVisible } = this.getLineCount();
      if (lineCountWhenCountVisible === 1 && lineCountWhenCountInvisible === 1) {
        this.displayTitle = this.displayTitle ? this.displayTitle : this.title;;
        this.updateDom();
      }
      else if (lineCountWhenCountVisible === 2 && lineCountWhenCountInvisible === 1) {
        const titleString = (this.displayTitle ? this.displayTitle : this.title).replaceAll('<br/>', '');
        let lastIndex = titleString.lastIndexOf(" ");
        this.displayTitle = `${titleString.slice(0, lastIndex) + '<br/>' + titleString.slice(lastIndex)}`;
        this.updateDom();
      } else if (lineCountWhenCountVisible === 2 && lineCountWhenCountInvisible === 2) {
        this.displayTitle = (this.displayTitle ? this.displayTitle : this.title).replaceAll('<br/>', '');
        this.updateDom();
      } else if (lineCountWhenCountVisible > 2 || lineCountWhenCountInvisible !== lineCountWhenCountVisible) {
        if (lineCountWhenCountVisible > 2) {
          const titleString = (this.displayTitle ? this.displayTitle : this.title).replaceAll('<br/>', '');
          let lastIndex = titleString.lastIndexOf(" ");
          this.displayTitle = `${titleString.substring(0, lastIndex)}...`;
          this.setTextOnMobile();
        } else {
          this.updateDom()
        }
      }
    })
    this.changeDetectionRef.markForCheck();
  }




  //This method is only responsible for handling desktop screens
  setTextOnDesktop() {
    setTimeout(() => {
      const { lineCountWhenCountInvisible, lineCountWhenCountVisible } = this.getLineCount();
      const titleString = this.displayTitle ? this.displayTitle : this.title;
      if (lineCountWhenCountVisible === 1 && lineCountWhenCountInvisible === 1) {
        this.displayTitle = titleString;
        this.updateDom();
      }
      if (lineCountWhenCountVisible > 1) {
        let lastIndex = titleString.lastIndexOf(" ");
        this.displayTitle = `${titleString.substring(0, lastIndex)}...`;
        this.setTextOnDesktop();
      } else {
        this.updateDom()
      }
    })
    this.changeDetectionRef.markForCheck();
  }



  getLineCount() {
    const titleElement = this.titleElement?.nativeElement;
    const count = this.countElement?.nativeElement;
    this.renderer.setStyle(count, 'display', 'none');
    const titleStyle = this.getTitleStyle();
    const lineHeight = parseFloat(titleStyle.lineHeight);
    const containerHeight = parseFloat(titleElement.clientHeight);
    const lineCountWhenCountInvisible = Math.round(containerHeight / lineHeight);
    this.renderer.setStyle(count, 'display', 'inline');
    const titleStyle1 = this.getTitleStyle();
    const lineHeight1 = parseFloat(titleStyle1.lineHeight);
    const containerHeight1 = parseFloat(titleElement.clientHeight);
    const lineCountWhenCountVisible = Math.round(containerHeight1 / lineHeight1);
    return { lineCountWhenCountInvisible, lineCountWhenCountVisible };
  }


  getTitleStyle() {
    const titleElement = this.titleElement?.nativeElement
    return window.getComputedStyle(titleElement);
  }

}
