<section class="discover_product_listing">
  <div  class="mx-1140">
    <div class="discover_listing_inner">
      @if(homepageService.SPACE_IMAGE_AVAILABLE()) {
        <h2 class="heading_2 discover_heading">More to discover</h2>
        <div class="right_sec arrow_btn">
          <button [disabled]="disableSlickButtons()" [ngClass]="{'slick-disabled' : disableSlickButtons()}"
            (click)="carousel.previousPage()" type="button" class="slick-prev slick-arrow" aria-disabled="true">
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.60531 0.826294L1.52637 6.90524L7.60531 12.9842" stroke="black" stroke-width="1.51974"
                stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </button>
          <button [disabled]="disableSlickButtons()" [ngClass]="{'slick-disabled' : disableSlickButtons()}"
            (click)="carousel.nextPage()" type="button" class="slick-next slick-arrow" aria-disabled="true">
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.39469 0.826294L7.47363 6.90524L1.39469 12.9842" stroke="#222222" stroke-width="1.51974"
                stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </button>
        </div>
      <div class="discover_product_wrapper product-carousel_wrapper">
        <div class="ngx_carousel_wrapper" id="slick_favorite">
          <app-carousel #carousel [items]="homepageService.SPACE_LIST() | spaceFilter: currentSpaceId()"
          [carouselConfig]="carouselConfig" appWheelThrottle (throttledWheel)="homepageService.scrollCarousel($event, carousel)" [isDiscoverSpace]="true">
          <ng-template let-space #slide>

              <div class="slide">
                <div class="space_card" routerLink="/{{footerService.PropertyId()}}/{{space.subspaceName}}">
                  <figure>
                    <img class="space_banner"
                         src="{{space.image!}}"
                         loading="lazy"
                         appPlaceholderImgDirective type="space"
                         srcset="
                                  {{space.image! | imageSize: '_thumb' }} 800w,
                                  {{space.image! | imageSize: '_hero_phone' }} 1200w,
                                  {{space.image! | imageSize: '_hero_phone' }} 1920w,
                                  {{space.image! | imageSize: '_hero_desk' }} 2560w
                                "
                         sizes="
                                  (max-width: 800px) 800px,
                                  (max-width: 1200px) 1200px,
                                  (max-width: 1920px) 1920px,
                                  2560px
                                "
                         alt="{{space.title}}"/>
                        <div class="item_count_wrapper">
                          <span class="tag_icons">
                              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.57747 13.5001C5.11284 13.5001 4.67578 13.319 4.347 12.9905L0.509625 9.15313C0.180844 8.82435 0 8.38729 0 7.92266C0 7.45804 0.181125 7.02098 0.509625 6.69219L6.69206 0.509757C7.09425 0.10757 7.66687 -0.0729932 8.22937 0.0271318L11.484 0.609882C12.1973 0.737569 12.7623 1.3026 12.89 2.01585L13.4727 5.27048C13.5728 5.83073 13.3926 6.40532 12.9901 6.80779L6.80794 12.9899C6.47916 13.3187 6.04209 13.4996 5.57747 13.4996V13.5001ZM7.92281 1.12513C7.76109 1.12513 7.60388 1.18898 7.48744 1.30541L1.30528 7.48757C1.06537 7.72748 1.06537 8.11785 1.30528 8.35776L5.14266 12.1951C5.38284 12.4353 5.77266 12.4353 6.01284 12.1951L12.195 6.01298C12.3373 5.87066 12.4009 5.66732 12.3654 5.46932L11.7827 2.21469C11.7377 1.96269 11.538 1.76301 11.2857 1.71773L8.03109 1.13498C7.99509 1.12851 7.95881 1.12541 7.92281 1.12541V1.12513Z" fill="#FCFCFC"/>
                                <path d="M9.84375 4.6875C10.3097 4.6875 10.6875 4.30974 10.6875 3.84375C10.6875 3.37776 10.3097 3 9.84375 3C9.37776 3 9 3.37776 9 3.84375C9 4.30974 9.37776 4.6875 9.84375 4.6875Z" fill="#FCFCFC"/>
                              </svg>
                            </span>
                          <span class="inner_counts">{{ space.productCount }}</span>
                        </div>
                  </figure>
                  <div class="space_content">
                    <p class="name_of_space">{{ space.title }}</p>
<!--                      <div class="count_wrapper">-->
<!--                        <span class="inner_count">{{ space.productCount }}</span>-->
<!--                      </div>-->
                  </div>
                </div>
              </div>
          </ng-template>
          </app-carousel>
        </div>
        </div>
      }
      <div class="btn_wrapper view_product_btn">
        @if(pageType !== 'all_products') {
          <div class="btn_blk">
            <a routerLink="/{{footerService.PropertyId()}}/all-products" class="button_primary" (click)="homepageService.blurButton($event); amplitudeService.viewAllProductsClick(pageType)">View All Products</a>
          </div>
        }
        <p class="text_link2">When you purchase an item on this page, Minoan and the property may earn a commission.
          By clicking a product link, you agree to our <a target="_blank" href="https://minoan.com/terms">Terms</a> and <a target="_blank" href="https://minoan.com/privacy">Privacy Policy</a>.</p>
      </div>

    </div>
  </div>
</section>
