import {Component, computed, input, Input, InputSignal} from '@angular/core';
import {HomepageService} from "../../../services/homepage/homepage.service";
import {FooterService} from "../../../services/footer/footer.service";
import {AmplitudeService} from "../../../services/tracking/amplitude.service";

@Component({
    selector: 'app-discover-spaces',
    templateUrl: './discover-spaces.component.html',
    styleUrl: './discover-spaces.component.scss',
    standalone: false
})
export class DiscoverSpacesComponent {

  currentSpaceId: InputSignal<string> = input.required();

  @Input({required: true}) pageType: 'homepage' | 'local_items' | 'our_favorites' | 'space_page' | 'all_products' = 'space_page'
  carouselConfig = {
    breakpoints: {
      "(max-width: 80000px)": {
        slides: { perView: 3, spacing: 30 },
      },
      "(max-width: 1100px)": {
        slides: { perView: 3, spacing: 10 },
      },
      "(max-width: 991px)": {
        slides: { perView: 2.5, spacing: 10 },
      },
      "(max-width: 767px)": {
        slides: { perView: 2, spacing: 10},
      },
      "(max-width: 599px)": {
        slides: { perView: 1.6, spacing: 10},
      },
      "(max-width: 480px)": {
        slides: { perView: 1.58, spacing: 10},
      },
    },
    drag: false,
    loop: true,
    slides: {
      perView: 3,
      spacing: 12,
    }
  }

  constructor(
    public homepageService: HomepageService,
    public footerService: FooterService,
    public amplitudeService: AmplitudeService,
  ) {

  }

  /**
   * To disable slick buttons
   */
  disableSlickButtons = computed(() => {
    return this.homepageService.SPACE_LIST().length <= 3;
  });

}
