import {afterNextRender, Component, Inject, OnDestroy, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser, NgClass} from "@angular/common";
import {HomepageService} from "../services/homepage/homepage.service";

@Component({
    selector: 'app-error-404',
    imports: [
        NgClass
    ],
    templateUrl: './error-404.component.html',
    styleUrl: './error-404.component.scss'
})
export class Error404Component implements OnDestroy {

  isServer: boolean = true;

  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    public homepageService: HomepageService,
  ) {
    afterNextRender({ write: () => {
        document.body.classList.add('error');
    } }, )

    if(isPlatformBrowser(this.platformId)) {
      this.isServer = false;
    }

  }

  ngOnDestroy() {
    if(isPlatformBrowser(this.platformId)) {
      document.body.classList.remove('error')
    }
  }

}
