import {Component, computed} from '@angular/core';
import {HomepageService} from "../../../services/homepage/homepage.service";
import {AmplitudeService} from "../../../services/tracking/amplitude.service";
import {getWebLink} from "../../../utils/common-function";

@Component({
    selector: 'app-co-branding',
    templateUrl: './co-branding.component.html',
    styleUrl: './co-branding.component.scss',
    standalone: false
})
export class CoBrandingComponent {

  constructor(
    public homepageService: HomepageService,
    public amplitudeService: AmplitudeService,
  ) {
  }

  CO_BRANDING_IMAGE = computed(() => {
    const featuredImage = this.homepageService.SHOWROOM_DETAILS()?.featuredImage;
    return featuredImage ? featuredImage : this.homepageService.SHOWROOM_DETAILS()?.image;
  })

  openLogoLink(event: any) {
    if(!this.homepageService.SHOWROOM_DETAILS()?.propertyLogoLink) {
      event.preventDefault();
    }
  }

  curatedBy = computed (() =>{
    if(this.homepageService.SHOWROOM_DETAILS()?.curatedBy === 'account_name'){
      return this.homepageService.SHOWROOM_DETAILS()?.companyName;
    } else {
      return this.homepageService.SHOWROOM_DETAILS()?.showroomName;
    }
  });

  protected readonly getWebLink = getWebLink;
}
