import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {HomepageService} from "../services/homepage/homepage.service";

/**
 * Guard to prevent access to unknown spaces.
 * @param route Activated Route Snapshot
 * @param state Current Router State
 */
export const spaceGuard: CanActivateFn = async (route, state) => {
  const homepageService = inject(HomepageService)
  const router: Router = inject(Router);
  const propertyId = state.url.split('/')[1]
  const homeUrl = router.parseUrl(`/${propertyId}`)

  if(!homepageService.PROPERTY_DETAILS()) {
    await homepageService.fetchPageInformation(propertyId);
  }
  const spaceId = route.params['spaceId'];
  let spaceIdExists = !!homepageService.PROPERTY_DETAILS()?.spaceList.find(each => each?.subspaceName! === spaceId && !!each.productCount)

  if(!spaceIdExists) {
    spaceIdExists = !!homepageService.PROPERTY_DETAILS()?.showroomDetails.allProductList.productCount && !!homepageService.PROPERTY_DETAILS()?.showroomDetails.allProductList.products.find(each => each.showroomProductName === spaceId)
  }

  return spaceIdExists ? true : homeUrl;
};
