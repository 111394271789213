<!-- co brand module html start here  -->
<section class="co_brand_module_sec">
  <div class="mx-1660 container">
    <div class="co_brand_inner_sec">
      <div class="left_sec">
        <figure class="brand_img_sec">
          @if (CO_BRANDING_IMAGE()) {
            <img
              src="{{CO_BRANDING_IMAGE()!}}"
              srcset="
                  {{CO_BRANDING_IMAGE()! | imageSize: '_hero_phone' }} 800w,
                  {{CO_BRANDING_IMAGE()! | imageSize: '_hero_phone' }} 1200w,
                  {{CO_BRANDING_IMAGE()! | imageSize: '_hero_desk' }} 1920w,
                  {{CO_BRANDING_IMAGE()! }} 2560w
                "
              sizes="
                  (max-width: 800px) 800px,
                  (max-width: 1200px) 1200px,
                  (max-width: 1920px) 1920px,
                  2560px
                "
              onError="this.src='assets/images/hero_banner.jpg'" alt="{{homepageService.SHOWROOM_DETAILS()?.showroomName}}" />
          } @else {
            <img src="assets/images/hero_banner.jpg" alt="{{homepageService.SHOWROOM_DETAILS()?.showroomName}}">
          }
        </figure>
      </div>
      <div class="right_sec">
        <div class="co_brand_desc">
          @if(homepageService.SHOWROOM_DETAILS()?.propertyLogo) {
            <div class="co_brand_img">
              <a href="{{homepageService.SHOWROOM_DETAILS()?.propertyLogoLink | applyFn: getWebLink}}" [ngClass]="{'deactive-logo-link' : homepageService.SHOWROOM_DETAILS()?.propertyLogoLink == ''}" (click)="openLogoLink($event); amplitudeService.propertyLogoClick(homepageService.SHOWROOM_DETAILS()?.propertyLogoLink!)" target="_blank">
                <img [ngClass]="{'br-100': img.height === img.width}" [src]="homepageService.SHOWROOM_DETAILS()?.propertyLogo" #img  alt="{{ homepageService.SHOWROOM_DETAILS()?.showroomName }}" />
              </a>
            </div>
          }
          <label class="subtitle1">Curated by&nbsp;{{ curatedBy() }},&nbsp;<span class="powered_by"> powered by Minoan</span></label>
          <h1 class="heading_5">
            Touch, feel, and try before you buy — with Minoan, shopping becomes an immersive experience that transforms the way you discover and select products.
          </h1>
          @if(homepageService.SHOWROOM_DETAILS()?.propertyLogoLink){
            <a class="text_link1" (click)="amplitudeService.showroomEntryEvents.bookYourStayClickEvent('footer')" [href]="homepageService.SHOWROOM_DETAILS()?.propertyLogoLink | applyFn: getWebLink" target="_blank">Book a stay at <span>{{ homepageService.SHOWROOM_DETAILS()?.showroomName }}</span></a>
          }
        </div>
      </div>
    </div>
  </div>
</section>
